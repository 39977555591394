.content2 {
    height:80vh;
    width:100vw;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
 }

 .react-parallax  {
display:flex;
flex-direction: column;
justify-content: flex-end;

 }

 
 .image {
    height:70vh
 }

 @media (max-width:800px) {
    .content2 {
        height:100%;
        width:100vw;
     }
 }
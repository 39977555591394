@font-face {
    font-family: 'Agbalumo';
    src: local('Agbalumo'), 
         url('../Fonts/Agbalumo-Regular.ttf') format('truetype');
      
    font-weight: normal;
    font-style: normal;
  }
  
  .logo:hover {
    cursor: pointer;
  }
  .header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    
  }


  
  .header-bg {
    background-color: rgba(0, 0, 0, 0.836);
  }
  
  .header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    margin: 0px;
    height: 100%;
    padding: 0rem;
    padding-right: 2rem;
  }
  
  .header .nav-menu a {
    color: #ffffff;
  }
  
  .header .navbar img {
    width: 100px;
    height: auto;
    
  }
  
  .header .nav-menu {
    display: flex;
  }
  
  .header .nav-item {
    padding: 0.7rem;
    cursor: pointer;
    list-style: none;
    font-size:x-large;
    
    font-family: Agbalumo;
  }
  
  .header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid whitesmoke;
  }
  
  .hamburger {
    display: none;
  }
  
  .nav-item a {
    text-decoration: none; /* Removes the underline */
    text-transform: uppercase;
  }
  
  
  @media (max-width:800px) {
    .header {
        max-width: 100vw;
        
    }
  
    .header .navbar {
        max-width: 100%;
    }
  
    .hamburger {
        display: block;
    }
  
    .nav-menu {
        position: fixed;
        left: -120%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.904);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }
  
    .nav-menu.active {
        left: 0;
    }
  
    .nav-item {
        margin: 1.5rem 0;
    }
  
    .header .navbar img {
        width: 100px;
    }
  }

@font-face {
    font-family: 'Agbalumo';
    src: local('Agbalumo'), 
         url('../Fonts/Agbalumo-Regular.ttf') format('truetype');
      
    font-weight: normal;
    font-style: normal;
  }

.footer {
 
    background-color: #000;
    color: #eee;
    padding: 2rem 1rem;
    height:fit-content;

}

.footer .container {
    
    margin: 0 auto;
    text-align: center;
    height:fit-content;
}

.footer ul {
    display: flex;
    justify-content: center;
    padding:0;
    font-family: 'Agbalumo';
 
    
}

.footer li {
    padding: 0.5rem;
    list-style: none;

}

.footer li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
  
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: white solid 3px;
    cursor: pointer;
}

.footer img {
    width:10rem;
}
.bottom {
    text-align: center;
    padding-top: 3rem;
}

.line {
    margin-bottom: 1rem;
}

@media (max-width:800px) {
    .footer{max-height: fit-content;
    }
    .footer ul {
        display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }
}
@font-face {
    font-family: 'Agbalumo';
    src: local('Agbalumo'), 
         url('../Fonts/Agbalumo-Regular.ttf') format('truetype');
      
    font-weight: normal;
    font-style: normal;
  }


.SocialMedia {
    background: rgba(255, 255, 255, 0.815);
 
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    transition: background-color 0.3s;
}

 .FollowUs h1{
    font-family: 'Agbalumo';
    font-weight: lighter;
 }

 
.SocialMediaIcons {
    font-size: 2rem;
    display: inline-flex;
    justify-content: center;
    gap: 20px;
     
}

.SocialMediaIcons a {
    color: black;
}

.SocialMediaIcons svg {
    transition: transform 0.3s ease;
}

.SocialMediaIcons svg:hover {
    transform: scale(1.2);
}
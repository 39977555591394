.ServiceContainer {
   display: flex;
   flex-direction: column;
   background: linear-gradient(to bottom, white, black);
   border: 3px solid black;
   border-radius: 3px;
   height:fit-content;
  
}



 

.OuterServiceContainer {
   display:flex;
   flex-direction: column;
}

 
.ServicePics {
    display: flex;
     
    align-items:flex-start;
    justify-content: center;
 
   color: rgb(3, 3, 3);
   height:15vh;
   width:100%;
   margin:0 auto;
   height:fit-content;

}

.BookNow{
  background-color: black;
    color: rgb(255, 255, 255);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: fit-content;
    margin: 0 auto;
    font-family:'Times New Roman', Times, serif;
    padding: 0 10px;
    border-bottom: 3px black solid;
    border-left: 3px black solid;
    border-right:3px black solid;
 
    
    
}

 

.ServicePics img {
  
 width:20%;
 margin:5px;
 border: 3px black solid;
}

.ServicePics img:hover {
   cursor: pointer
  }
  

.BookNow:hover{
    transform: scale(1.10);
    background-color: black;
    color: white;
}

.BookNow a {
 text-align: center;
 margin: 0 auto;
 font-size: 2rem;
 padding:0.5rem;
 color: white;
 text-decoration: none;
}

.ServiceName{
   font-size: 2rem;
}
 
  @media (max-width:1000px) {
 
     .ServiceContainer Img {
        border-radius: 0;
 
        border: 3px solid black;
      }

  }


  @media (max-width:600px) {
 
         .ServiceContainer {
            flex-direction: column;
            border-radius: 7px;
         }


         .ServicePics{
            height:fit-content;
            width:60vw;
         }
      
         .BookNow a{
      font-size: 2rem;
          
      }
  
    
    
    
  }